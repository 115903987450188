import React from "react";
import tjs from "../imgs/Thijs.jpeg";
import wolf from "../imgs/Woof_wink.png";
function Modal({ onClose, isCorrect }) {
  return (
    <div className="modal">
      <div className="modal-content">
        <img
          src={isCorrect ? wolf : tjs}
          alt={isCorrect ? "Correct" : "Wrong"}
          role="presentation"
          style={{ width: "auto", height: "200px" }}
        />
        <h1>Your answer was {isCorrect ? "correct" : "wrong"}!</h1>
        <button onClick={onClose} className="button">
          Close
        </button>
      </div>
    </div>
  );
}

export default Modal;
