import React, { Fragment } from "react";
import { getData } from "./getData";
import Modal from "./Modal";

class MainQuiz extends React.Component {
  state = {
    currentQuestion: 0,
    myAnswer: null,
    options: [],
    score: 0,
    disabled: true,
    isEnd: false,
    quizData: [],
    isAnswered: false,
    wasCorrect: false,
    correctAnswers: [],
  };

  loadQuizData = async () => {
    const data = await getData();

    this.setState(() => {
      return {
        questions: data[this.state.currentQuestion].question,
        answer: data[this.state.currentQuestion].answer,
        options: data[this.state.currentQuestion].options,
        quizData: data,
      };
    });
  };

  componentDidMount() {
    this.loadQuizData();
  }

  onClose = () => {
    this.setState({ isAnswered: false });
  };
  nextQuestionHandler = () => {
    // console.log('test')
    const { myAnswer, answer, score } = this.state;

    this.setState({ isAnswered: true });

    if (myAnswer === answer) {
      this.setState({
        score: score + 1,
        wasCorrect: true,
      });
      this.state.correctAnswers.push(this.state.currentQuestion);
    }

    this.setState({
      currentQuestion: this.state.currentQuestion + 1,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentQuestion !== prevState.currentQuestion) {
      this.setState(() => {
        return {
          disabled: true,
          questions: this.state.quizData[this.state.currentQuestion].question,
          options: this.state.quizData[this.state.currentQuestion].options,
          answer: this.state.quizData[this.state.currentQuestion].answer,
        };
      });
    }
  }
  //check answer
  checkAnswer = (answer) => {
    this.setState({ myAnswer: answer, disabled: false, wasCorrect: false });
  };
  finishHandler = () => {
    if (this.state.currentQuestion === this.state.quizData.length - 1) {
      this.setState({
        isEnd: true,
      });
    }
    if (this.state.myAnswer === this.state.answer) {
      this.setState({
        score: this.state.score + 1,
      });
    }
  };
  render() {
    const {
      options,
      myAnswer,
      currentQuestion,
      isEnd,
      quizData,
      disabled,
      questions,
      isAnswered,
    } = this.state;

    // // delete duplicate options
    // const uniqueOptions = Array.from(new Set(options));
    return isEnd ? (
      <Fragment>
        <div className="centerContainer">
          <div className="result">
            <h3>Game Over your Final score is {this.state.score} points </h3>
            <div>
              The correct answers for the questions were
              <ul>
                {this.state.quizData.map((item, index) => (
                  <li className="results options" key={index}>
                    Q#{index}: {item.answer}{" "}
                    {this.state.correctAnswers.some((x) => {
                      return x === index;
                    }) ? (
                      <span style={{ color: "green" }}>Correct</span>
                    ) : (
                      <span style={{ color: "red" }}>Wrong</span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <div className="centerContainer">
          <h1>{questions} </h1>
          <span>{`Questions ${currentQuestion}  out of ${quizData.length} remaining `}</span>
          <div className="questionsContainer">
            {options.map((option, index) => (
              <p
                key={index}
                className={`options
                ${myAnswer === option ? "selected" : null}
                `}
                onClick={() => this.checkAnswer(option)}
              >
                {option}
              </p>
            ))}
            {currentQuestion < quizData.length - 1 ? (
              <button
                className="button"
                disabled={disabled}
                onClick={this.nextQuestionHandler}
              >
                Next
              </button>
            ) : (
              <button
                className="ui inverted button"
                onClick={this.finishHandler}
              >
                Finish
              </button>
            )}
          </div>
        </div>
        {isAnswered && (
          <Modal onClose={this.onClose} isCorrect={this.state.wasCorrect} />
        )}
      </Fragment>
    );
  }
}

export default MainQuiz;
