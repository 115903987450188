import React from "react";
import "./App.css";

import MyQuiz from "./components/MainQuiz";
function App() {
  return (
    <div className="App">
      <MyQuiz />
    </div>
  );
}

export default App;
