export const getData = async () =>
  fetch("https://www.wooftrivia.com/api")
    .then((response) => response.json())
    .then((data) => {
      // let transformedData = data.map((q, i) => {
      //   return {
      //     id: i,
      //     answer: q.correct_answer,
      //     question: q.question,
      //     options: q.incorrect_answers,
      //   };
      // });

      // const uniqueOptions = Array.from(new Set(data.options));
      data.forEach((element) => {
        element.options = Array.from(new Set(element.options));
        shuffle(element.options);
      });
      console.log(data);
      return data;
    });

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
